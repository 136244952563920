import { Client, CustomField, CustomFieldItem, CustomFieldSelection } from '../entities/household';
import { HouseholdService } from './household.service';
import { Injectable } from '@angular/core';
import { getCustomFieldSelectionSingle } from '../shared/util';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldService {

  constructor(private householdService: HouseholdService) { }

  getCustomField(customFieldID: number, isPrimary: boolean): CustomField | null {
    return isPrimary ? this.getPrimaryCustomField(customFieldID) : this.getSpouseCustomField(customFieldID);
  }

  getPrimaryCustomField(customFieldID: number): CustomField | null {
    if (!this.householdService.household) return null;

    return CustomFieldService.getCustomField(this.householdService.household.client, customFieldID);
  }

  getSpouseCustomField(customFieldID: number): CustomField | null {
    if (!this.householdService.household?.spouse) return null;

    return CustomFieldService.getCustomField(this.householdService.household.spouse, customFieldID);
  }

  updateCustomField(customFieldID: number, value: string, isPrimary: boolean): void {
    if (!this.householdService.household) return;

    const customField = this.getCustomField(customFieldID, isPrimary);

    if (customField) customField.value = value;
  }

  updateSpouseCustomField(customFieldID: number, value: string): void {
    if (!this.householdService.household?.spouse) return;

    const customField = CustomFieldService.getCustomField(this.householdService.household.spouse, customFieldID);
    customField.value = value;
  }

  getHouseholdCustomFieldSelection(customFieldID: number): CustomFieldSelection | undefined {
    return this.householdService.household?.householdCustomFieldSelections.find(x => x.customFieldID === customFieldID)
  }

  getHouseholdCustomFieldSingleSelection(customFieldID: number): CustomFieldItem | null {
    return getCustomFieldSelectionSingle(this.householdService.household?.householdCustomFieldSelections ?? [], customFieldID);
  }

  private static getCustomField(client: Client, customFieldID: number): CustomField {
    let customField = client.customFields.find(x => x.customFieldID === customFieldID);

    if (!customField) {
      customField = {
        customFieldID
      }

      client.customFields.push(customField);
    }

    return customField;
  }
}
